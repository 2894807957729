import * as React from 'react';
import { Link, graphql } from 'gatsby';
import {
  Layout,
  SEO,
  CJumbotron,
  CBreadCrumb,
  LWrap,
  CGoogleMap,
  CFixedImg,
  CCard,
  LContact,
} from '../../../../components/_index';

// markup
const SubPage = ({ data }: { data: any }) => {
  const frontmatter = data?.markdownRemark?.frontmatter;

  return (
    <Layout>
      <SEO title={frontmatter?.title} description={frontmatter?.description} />
      <CJumbotron
        size="small"
        data={{
          title: {
            main: 'GUIDE TO THE AREA',
            sub: '周辺案内',
          },
          imgList: [
            {
              img: {
                src: '/assets/images/about/access/surroundings/kv.png',
              },
              imgSp: {
                src: '/assets/images/about/access/surroundings/kv__sp.png',
              },
            },
          ],
        }}
      >
        <CBreadCrumb
          data={{
            parent: [],
            current: {
              label: '周辺案内',
            },
          }}
        />
      </CJumbotron>
      <section className="u_mbExlarge l_sect">
        <LWrap exClass="u_mbExLarge">
          <CFixedImg
            exClass="u_mbSmall"
            width={840}
            img={{
              src: '/assets/images/about/access/surroundings/img_surroundings13.png',
              alt: '',
            }}
          />
          <p className="c_sectLead">ホテル周辺の観光スポットのご紹介です。</p>
          <CGoogleMap />
          <CCard
            data={[
              {
                img: {
                  src: '/assets/images/about/access/surroundings/img_surroundings.png',
                  alt: '',
                },
                link: {
                  href: 'https://www.yokohama-landmark.jp/',
                  blank: true,
                },
                title: <>ランドマークプラザ</>,
                content: <>ホテル隣接のショッピングモール</>,
              },
              {
                img: {
                  src: '/assets/images/about/access/surroundings/img_surroundings02.png',
                  alt: '',
                },
                link: {
                  href: 'https://www.mec-markis.jp/mm/',
                  blank: true,
                },
                title: <>MARK IS みなとみらい</>,
                content: <>みなとみらい駅直結のショッピングモール</>,
              },
              {
                img: {
                  src: '/assets/images/about/access/surroundings/img_surroundings03.png',
                  alt: '',
                },
                link: {
                  href: 'https://www.nippon-maru.or.jp/',
                  blank: true,
                },
                title: <>日本丸メモリアルパーク</>,
                content: (
                  <>ホテルの目の前、なだらかな緑地があるウォーターフロント。</>
                ),
              },
              {
                img: {
                  src: '/assets/images/about/access/surroundings/img_surroundings04.png',
                  alt: '',
                },
                link: {
                  href: 'https://yokohama.art.museum/',
                  blank: true,
                },
                title: <>横浜美術館</>,
                content: <>多彩な企画展を開催しているホテル至近の美術館。</>,
              },
              {
                img: {
                  src: '/assets/images/about/access/surroundings/img_surroundings05.png',
                  alt: '',
                },
                link: {
                  href: 'https://www.cupnoodles-museum.jp/ja/yokohama/',
                  blank: true,
                },
                title: <>カップヌードルミュージアム（安藤百福発明記念館）</>,
                content: (
                  <>インスタントラーメンにまつわる体験型ミュージアム。</>
                ),
              },
              {
                img: {
                  src: '/assets/images/about/access/surroundings/img_surroundings06.png',
                  alt: '',
                },
                link: {
                  href: 'https://www.manyo.co.jp/mm21/',
                  blank: true,
                },
                title: <>横浜みなとみらい 万葉倶楽部</>,
                content: (
                  <>湯河原・熱海温泉の湯をお楽しみいただける温泉施設。</>
                ),
              },
              {
                img: {
                  src: '/assets/images/about/access/surroundings/img_surroundings07.png',
                  alt: '',
                },
                link: {
                  href: 'https://www.welcome.city.yokohama.jp/chinatown/',
                  blank: true,
                },
                title: <>横浜中華街</>,
                content: <>日本三大チャイナタウンの一つ、横浜中華街。</>,
              },
              {
                img: {
                  src: '/assets/images/about/access/surroundings/img_surroundings08.png',
                  alt: '',
                },
                link: {
                  href: 'http://cosmoworld.jp/',
                  blank: true,
                },
                title: <>よこはまコスモワールド</>,
                content: <>観覧車「コスモクロック21」がシンボルの遊園地。</>,
              },
              {
                img: {
                  src: '/assets/images/about/access/surroundings/img_surroundings09.png',
                  alt: '',
                },
                link: {
                  href: 'https://www.welcome.city.yokohama.jp/spot/details.php?bbid=190',
                  blank: true,
                },
                title: <>山下公園</>,
                content: <>海岸沿いに広がる横浜の代表的な観光スポット。</>,
              },
              {
                img: {
                  src: '/assets/images/about/access/surroundings/img_surroundings10.png',
                  alt: '',
                },
                link: {
                  href: 'https://www.yokohama-akarenga.jp/',
                  blank: true,
                },
                title: <>横浜赤レンガ倉庫</>,
                content: <>レストランやライブハウスもある煉瓦造りの建物。</>,
              },
              {
                img: {
                  src: '/assets/images/about/access/surroundings/img_surroundings11.png',
                  alt: '',
                },
                link: {
                  href: 'https://osanbashi.jp/',
                  blank: true,
                },
                title: <>大桟橋</>,
                content: <>世界中の人々をお迎えする横浜港の玄関。</>,
              },
              {
                img: {
                  src: '/assets/images/about/access/surroundings/img_surroundings12.png',
                  alt: '',
                },
                link: {
                  href: 'https://www.pacifico.co.jp/',
                  blank: true,
                },
                title: <>パシフィコ横浜</>,
                content: <>日本最大の複合コンベンションセンター。</>,
              },
              {
                img: {
                  src: '/assets/images/about/access/surroundings/img_surroundings14.png',
                  alt: '',
                },
                link: {
                  href: 'https://www.hamaoroshi.or.jp/observe?lang=ja',
                  blank: true,
                },
                title: <>横浜市中央卸市場</>,
                content: <>東日本で一番古い、港町の歴史ある市場</>,
              },
            ]}
          />
          <div className="u_mbSmall">
            <Link
              to="https://www.city.yokohama.lg.jp/kotsu/bus/unchin/minatoburari.html"
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className="body">
                <p className="title">
                  みなとぶらりチケット<i className="icon-blank"></i>
                </p>
              </div>
            </Link>
            <div className="content">
              横浜ベイエリアの市営地下鉄（ブルーライン）と市営バスが乗り放題の1日乗車券。
            </div>
          </div>
          <div className="u_mbSmall">
            <Link
              to="https://www.premiumoutlets.co.jp/gotemba/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className="body">
                <p className="title">
                  御殿場プレミアムアウトレット<i className="icon-blank"></i>
                </p>
              </div>
            </Link>
            <div className="content">
              世界文化遺産の富士山が見える広大な敷地に、国内最大の面積を誇る、日本を代表するショッピングリゾートです。
              <br />
              ※ホテルより直行バスを運行しております。
              <br />
              <Link to="/about/access/bus/">詳しくはこちら</Link>
            </div>
          </div>
          <div className="u_mbSmall">
            <Link
              to="https://www.seaparadise.co.jp/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className="body">
                <p className="title">
                  横浜・八景島シーパラダイス<i className="icon-blank"></i>
                </p>
              </div>
            </Link>
            <div className="content">
              ４つの水族館とさまざまなアトラクション、バラエティ豊かなショッピングストア、レストラン、ホテルがそろった複合型海洋レジャー施設です。
              <br />
              ※ホテルより直行バスを運行しております。
              <br />
              <Link to="/about/access/bus/">詳しくはこちら</Link>
            </div>
          </div>
        </LWrap>
      </section>
      <section className="u_mbMedium">
        <LWrap>
          <ul className="c_noteList">
            <li>画像は全てイメージです。</li>
          </ul>
        </LWrap>
      </section>
      <LContact
        tel={[
          {
            title: '代表',
            number: '045-221-1111',
          },
        ]}
      />
    </Layout>
  );
};

export default SubPage;

export const query = graphql`
  query ($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        description
      }
    }
  }
`;
